import Slider from "react-slick";
import React from "react";
import "./banner.css";
import { bannerdata } from "../../database/db";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings} className="banner-container">
     {bannerdata.map((item) => (
  <div key={item.id} className="banner-slide">
    <img
      src={item.path}
      alt={`Slide ${item.id}`}
      className="banner-image"
    />
  </div>
))}
    </Slider>
  );
};

export default Banner;
