import React from "react";
import { Link } from "react-router-dom";
import "./PolicyModal.css"; // Add CSS styles for the modal here

const PolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render anything if the modal is not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h1 className="modal-title">Our Policies</h1>
        <ul className="policy-links">
          <li>
            <Link to="/terms" onClick={onClose}>Terms and Conditions</Link>
          </li>
          <li>
            <Link to="/cancellation" onClick={onClose}>Cancellation and Refund</Link>
          </li>
          <li>
            <Link to="/shipping" onClick={onClose}>Shipping and Delivery</Link>
          </li>
          <li>
            <Link to="/privacypolicy" onClick={onClose}>Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PolicyModal;
