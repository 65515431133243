// CategoryModal.js
import React from 'react';
import './CategoryModal.css';

const CategoryModal = ({ isOpen, onClose, items, onItemClick }) => {
    if (!isOpen) return null;

    return (
        <div className="category-modal-overlay" onClick={onClose}>
            <div 
                className="category-modal-content" 
                onClick={(e) => e.stopPropagation()} // Prevents modal close on content click
            >
                <button className="close-button" onClick={onClose}>×</button>
                <ul className="category-list">
                    {items.map((item) => (
                        <li key={item._id} onClick={() => { onItemClick(item.SKU_id); onClose(); }}>
                            {item.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CategoryModal;
