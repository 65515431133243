import React, { useState } from 'react';
import axios from 'axios';
import { createFeedBackURL } from '../api/api';
 
const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      const response = await axios.post(createFeedBackURL, {
        name,
        email,
        phone,
        message,
      });
 
      if (response.status === 201) {
        setIsSubmitted(true);
        setError('');
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
      }
    } catch (err) {
      setIsSubmitted(false);
      setError('An error occurred. Please try again.');
      console.error('Feedback submission error:', err);
    }
  };
 
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
      }}className='md:mb-0 mb-10'
    >
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          padding: '40px',
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <h1 style={{ textAlign: 'center', marginBottom: '30px', color: '#333', fontSize: '48px', fontWeight: 'bold' }}>
          Contact Us
        </h1>
 
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#777' }}>
          <strong>Last updated on Oct 23 2024</strong>
        </p>
 
        <section style={{ marginTop: '30px', color: '#555', lineHeight: '1.6' }}>
          <h2 style={{ fontSize: '24px', marginBottom: '20px', color: '#444' }}>
            You may contact us using the information below:
          </h2>
 
          <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
            <li style={{ marginBottom: '10px' }}>
              <strong>Merchant Legal Entity Name:</strong> ESPS SOLUTIONS PRIVATE LIMITED
            </li>
            <li style={{ marginBottom: '10px' }}>
              <strong>Registered Address:</strong> DIGIUNCLE CREATIONS, ACHIEVERS CENTER POINT, LEVEL-1,
              KALINDI HILLS, SECTOR-49 Faridabad HARYANA 121001
            </li>
            <li style={{ marginBottom: '10px' }}>
              <strong>Operational Address:</strong> DIGIUNCLE CREATIONS, ACHIEVERS CENTER POINT, LEVEL-1, KALINDI HILLS, SECTOR-49 Faridabad HARYANA 121001
            </li>
            <li style={{ marginBottom: '10px' }}>
              <strong>Mobile No:</strong>{' '}
              <a href="tel:9717298198" style={{ textDecoration: 'none', color: '#555' }}>
                9717298198
              </a>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <strong>Telephone No:</strong>{' '}
              <a href="tel:01294067217" style={{ textDecoration: 'none', color: '#555' }}>
                01294067217
              </a>
            </li>
            <li>
              <strong>Email ID:</strong>{' '}
              <a href="mailto:yogenderpsingh007@gmail.com" style={{ textDecoration: 'none', color: '#555' }}>
                yogenderpsingh007@gmail.com / admin@spsolutions.org.nz
              </a>
            </li>
          </ul>
        </section>
 
        <div className="contact-form" style={{ marginTop: '40px' }}>
          <h2 style={{ fontSize: '24px', marginBottom: '20px', color: '#333', textAlign: 'center' }}>
            Any questions or feedback?
          </h2>
 
          <form onSubmit={handleSubmit}>
            <label htmlFor="name"><b>Name:</b></label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Your Name"
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
              required
            />
 
            <label htmlFor="email"><b>Email:</b></label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
              required
            />
 
            <label htmlFor="phone"><b>Phone:</b></label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Your Phone Number"
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
            />
 
            <label htmlFor="message"><b>Your Message:</b></label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your Message"
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '15px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                minHeight: '100px',
              }}
              required
            />
 
            <input
              type="submit"
              value="SUBMIT"
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#28a745',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            />
          </form>
 
          {isSubmitted && (
            <p style={{ color: 'green', marginTop: '15px', textAlign: 'center' }}>
              Thank you! Your feedback has been submitted.
            </p>
          )}
          {error && (
            <p style={{ color: 'red', marginTop: '15px', textAlign: 'center' }}>
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
 
export default ContactUs;
 
 