import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // For navigation
import toast from 'react-hot-toast';
import { useAuthContext } from '../context';

const ResetPassword = () => {
  const { resetPassword } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);

  
  // Retrieve email or mobile from location state, fallback to localStorage if not present
  const email =  location?.state?.email || location?.state?.Forgotemail;
  const resetemail =  location?.state?.email;
  const mobile =  location?.state?.Forgotmobile;
  const Forgotemail = location?.state?.Forgotemail;
  const fromProfile = location?.state?.fromProfile;
    console.log(email,resetemail,mobile,Forgotemail,fromProfile);

  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!newPassword || !confirmPassword) {
      toast.error('Both fields are required');
      return;
    }
  
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
  
  
    try {
      // Call resetPassword function with the correct data
      const result = await resetPassword({ email,mobile, newPassword });
      if (result) {
        toast.success('Password Reset Successfully');
        if (Forgotemail || mobile) {
          navigate('/login');
        }
        else if(resetemail) {
          console.log("reset");
          navigate('/profile');
        }
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Failed to reset password. Please try again.');
    }
  };
  

  return (
    <div className="h-fit flex items-start justify-center pt-8 pb-2 px-4 mb-20 md:mb-0">
    <div className="w-full max-w-xl bg-white border border-gray-200 rounded-xl shadow-lg p-8 space-y-8">
      <h2 className="text-2xl font-bold text-gray-800 text-center">Reset Your Password</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block font-medium text-gray-700 mb-1">New Password</label>
          <input
            type={passwordToggle ? "text" : "password"}
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-200"
            placeholder="Enter new password"
            required
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-1">Confirm New Password</label>
          <input
            type={passwordToggle ? "text" : "password"}
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-200"
            placeholder="Confirm new password"
            required
          />
        </div>

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={passwordToggle}
            onChange={handlePasswordToggle}
            id="toggleConfirmPassword"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="toggleConfirmPassword" className="text-gray-700">Show password</label>
        </div>

        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-indigo-500 to-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:from-indigo-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>
);
};

export default ResetPassword;
