import React from 'react'; 
import './../App.css';
import { Link } from 'react-router-dom';

const Offlinestore = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

  return (
      <div className="offline min-h-screen flex flex-col items-center justify-center px-4 sm:px-8">
        {/* Store Title */}
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-900 text-center mb-8 sm:mb-10 mt-6">
          Visit Our Store
        </h1>

        {/* Video and Image Section (Responsive Grid) */}
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* Video Section */}
          <div className="relative bg-white rounded-lg shadow-xl overflow-hidden transform transition-transform hover:scale-105 duration-300">
            <div className="w-full h-48 sm:h-64">
              <video
                src="inside_vid.mp4"
                autoPlay
                muted
                loop
                className="w-full h-full object-cover"
                alt="Store Interior"
              />
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
              <h2 className="text-lg sm:text-xl font-semibold text-white">Step Inside</h2>
              <p className="text-xs sm:text-sm text-gray-300 mt-1">
                Tour of our cozy and welcoming store interior.
              </p>
            </div>
          </div>

          {/* Image Section */}
          <div className="relative bg-white rounded-lg shadow-xl overflow-hidden transform transition-transform hover:scale-105 duration-300">
            <img
              src="frontdoor.png"
              alt="Store Front Door"
              className="w-full h-48 sm:h-64 object-cover"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
              <h2 className="text-lg sm:text-xl font-semibold text-white">Visit Us</h2>
              <p className="text-xs sm:text-sm text-gray-300 mt-1">
                Discover unique Home-Decor/Cookware/Kitchen items that reflect your style.
              </p>
            </div>
          </div>
        </div>

        {/* About Section */}
        <div className="max-w-4xl mx-auto mt-10 sm:mt-12 bg-white rounded-lg shadow-lg p-6 sm:p-8 mb-20 md:0">
          <h3 className="text-center text-2xl sm:text-3xl font-bold text-gray-800 mb-4">Welcome to DigiUncle Creations</h3>
          <p className="text-sm sm:text-md text-gray-700 mb-4 sm:mb-6">
            At DigiUncle Creations, we believe that every home tells a story. Our passion for home decor is rooted in the idea that a beautifully curated space can transform everyday life into something extraordinary.
            Whether it’s the warmth of a decorative candle, the charm of a handcrafted wooden tray, or the elegance of our exquisite wall hangings, we’re here to help you create a haven that reflects your unique personality and style.
          </p>

          {/* Contact Information Section */}
          <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row justify-center gap-6 sm:gap-16">
            {/* Phone */}
            
            <div className="flex flex-col items-center text-gray-800">
              <img src='phone.png' alt="Phone Icon" className="h-8 w-8 sm:h-10 sm:w-10 mb-2" />
              <Link to="/contactus" onClick={scrollToTop} title="Contact details" className="transition-colors duration-300 hover:text-blue-500 hover:underline">Contact Us</Link>
              <p className="text-center text-sm">0129-4067217 / +91 9717298198</p>
            </div>

            {/* Location */}
            
            <div className="flex flex-col items-center text-gray-800 ">
            <img src='location.png' alt="Location Icon" className="h-8 w-8 sm:h-10 sm:w-10 mb-2" />
            <Link to="https://www.google.com/maps/place/DigiUncle+Creations/data=!4m2!3m1!1s0x0:0x313089fc3e02db55?sa=X&ved=1t:2428&ictx=111" rel="noopener noreferrer" target="_blank" title="Find us on Google Maps" className="transition-colors duration-300 hover:text-blue-500 hover:underline">
              <p className="text-center text-sm font-semibold">Location:</p>
              <p className="text-center text-sm">DigiUncle Creations, Achievers Center</p>
              <p className="text-center text-sm">Point, Level-1, Sector 49, Kalindi Hill</p>
              <p className="text-center text-sm">Faridabad, Haryana 121001</p>
              </Link>
            </div>
            
          </div>
      

          {/* Review Section */}
          <div className="mt-10 sm:mt-12 text-center">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">Share Your Experience</h3>
            <p className="text-sm sm:text-md text-gray-700 mb-6">
              We would love to hear your feedback! Please feel free to leave a review or check out what others are saying about us.
            </p>

            {/* Review Buttons */}
            <div className="flex justify-center gap-6">
              {/* Button to View Reviews */}
              <a
              title="Leave us a review"
                href="https://g.page/r/CVXbAj78iTAxECA/review"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-2 bg-blue-600 text-white rounded-full font-semibold text-xs md:text-lg hover:bg-blue-700 transition duration-300"
              >
                Leave a Review
              </a>

              {/* Button to View Reviews */}
              <a
              title="Check out our reviews"
                href="https://www.google.com/maps/place/DigiUncle+Creations/@28.3959162,77.2667709,17z/data=!4m18!1m9!3m8!1s0x390cdf2ccc7db611:0x313089fc3e02db55!2sDigiUncle+Creations!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9!3m7!1s0x390cdf2ccc7db611:0x313089fc3e02db55!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-2 bg-gray-800 text-white rounded-full font-semibold text-xs md:text-lg hover:bg-gray-900 transition duration-300"
              >
                View Reviews
              </a>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Offlinestore;
