import React from 'react';
import { Helmet } from 'react-helmet';
import { Banner, ProductCard } from '../components';
import { Link } from 'react-router-dom';
import { useProductContext } from '../context';
import GoogleAd from '../components/GoogleAdSense/GoogleAdSense';

const Home = () => {
    const { RecentProduct, loading } = useProductContext();
    const data = JSON.parse(localStorage.getItem("products")) || [];
    const data1 = data.slice(0, 10);

    if (loading) {
        return <div>Loading...</div>;
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <title>DigiUncle - Premium Quality Home Decor Items</title>
                <meta
                    name="description"
                    content="Discover DigiUncle's exclusive collection of premium handcrafted home decor items, including candle holders, wall decor, kitchenware, and more. Elevate your home with style."
                />
                <meta name="keywords" content="DigiUncle, home decor, candle holders, wall decor, handcrafted products, kitchenware, premium quality" />
                <meta property="og:title" content="DigiUncle - Premium Quality Home Decor Items" />
                <meta property="og:description" content="Shop DigiUncle's unique handcrafted products to transform your living space with elegance and style." />
                <meta property="og:url" content="https://digiuncle.co.in" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://digiuncle.co.in/image/splash.png" />
                <meta property="og:site_name" content="DigiUncle Creations"/>
            </Helmet>

            <div className="gap-0 py-4 relative">
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "DigiUncle",
                        "description": "Discover DigiUncle's exclusive collection of premium handcrafted home decor items, including candle holders, wall decor, kitchenware, and more.",
                        "url": "https://digiuncle.co.in"
                    })}
                </script>

                <div className="relative z-10">
                    <Banner />
                </div>
                <br />
                <div className="about-digiuncle bg-gray-100 text-center py-4 px-4">
                    <h2 className="text-2xl font-bold text-gray-800 mb-2">About DigiUncle Creations</h2>
                    <p className="text-base text-gray-600 max-w-4xl mx-auto">
                        At DigiUncle Creations, we blend traditional craftsmanship with modern designs to bring you unique and high-quality home decor items. From elegant candle holders to captivating wall art, every product is a testament to our commitment to style and functionality. Transform your living space and make every corner reflect your personality.
                    </p>
                </div>
                <div className="premium-quality-strip bg-orange-200 text-center py-3">
                    <span className="font-bold text-black text-xl sm:text-2xl md:text-3xl underline">
                        Premium Quality Guaranteed
                    </span>
                    <p className="mt-2 text-sm font-bold text-black sm:text-base md:text-lg max-w-4xl mx-auto">
                        DigiUncle Creations offers only the finest products, designed to meet your needs and exceed your expectations.
                    </p>
                </div>
                
                {/* Recently Viewed Section */}
                {RecentProduct?.length > 0 && (
                    <div className="py-5">
                        <h2
                            className="
                                mx-auto
                                bg-blue-200
                                text-center
                                rounded-full
                                font-serif font-semibold
                                text-2xl sm:text-3xl md:text-4xl
                                h-16 sm:h-20 md:h-24
                                w-[80%] sm:w-[70%] md:w-[50%] lg:w-[25%]
                                flex items-center justify-center
                                leading-none"
                        >
                            Recently Viewed
                        </h2>
                        <div className="px-4">
                            <ProductCard data={RecentProduct} />
                        </div>
                    </div>
                )}
                {/* Best Selling Section */}
                <div className={`py-${RecentProduct?.length > 0 ? '3' : '5'} md:py-3`}>
                    <h2
                        className="
                            mx-auto
                            bg-blue-200
                            text-center
                            rounded-full
                            font-serif font-semibold
                            text-2xl sm:text-3xl md:text-4xl
                            h-16 sm:h-20 md:h-24
                            w-[80%] sm:w-[70%] md:w-[50%] lg:w-[25%]
                            flex items-center justify-center
                            leading-none"
                    >
                        Best Selling
                    </h2>
                    <div className="px-4">
                        {data.length > 0 ? (
                            <ProductCard data={data1} />
                        ) : (
                            <p>No products available</p>
                        )}
                    </div>
                    <Link
                        to="/productlist"
                        onClick={scrollToTop}
                        className="py-2 flex w-full justify-end items-center px-5 mb-12 md:mb-0"
                    >
                        <button className="bg-orange-500 text-white text-lg px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300">
                            Explore More...
                        </button>
                    </Link>
                </div>
                {/* <div className='my-4'>
                <GoogleAd />
                </div> */}
            </div>
        </>
    );
};

export default Home;
