 const BASE_URL = 'https://api.digiuncle.co.in';
// const BASE_URL = 'http://localhost:8080'
 
export const signUpURL = `${BASE_URL}/user/create`;
export const LoginURL = `${BASE_URL}/user/login`;
export const userContextURL = `${BASE_URL}/user/get`;
export const userUpdateURL = `${BASE_URL}/user/update`;
export const verifyOtpURL = `${BASE_URL}/user/verify-otp`;
export const resetPasswordURL = `${BASE_URL}/user/reset-password`;
export const OTPsetURL = `${BASE_URL}/user/setOtp`;
export const googleLoginURL = `${BASE_URL}/user/auth/google-login`;
export const handleGoogleLoginSuccessURL = `${BASE_URL}/user/auth/googlelogin`;

export const add_productURL = `${BASE_URL}/product/create`;
export const updateProductURL = `${BASE_URL}/product/update-product`;
export const getproductURL = `${BASE_URL}/product/get`;

export const imageURL = `${BASE_URL}/upload/upload`;

export const createAddressURL=`${BASE_URL}/address/create`;
export const getAddressURL=`${BASE_URL}/address/get`;
export const updateAddressURL=`${BASE_URL}/address/upadte`;

export const paymentURL=`${BASE_URL}/payment/buy-order`;
export const verify_paymentURL=`${BASE_URL}/payment/verify`;
export const invoiceURL=`${BASE_URL}/payment/razorpay-invoice-endpoint`;

export const codURL=`${BASE_URL}/orders/cod-order`;
export const getOrdersURL=`${BASE_URL}/orders/get`;
export const updateOrderStatusURL=`${BASE_URL}/orders/update-status`;
export const getAllOrdersURL=`${BASE_URL}/orders/get-all`;

export const createReviewURL=`${BASE_URL}/review/create`;
export const getReviewURL=`${BASE_URL}/review/get`;

export const createFeedBackURL=`${BASE_URL}/feedback/submit`;

export const addToCartURL = `${BASE_URL}/cart/add-to-cart`;  
export const removeFromCartURL = `${BASE_URL}/cart/remove-to-cart`;  
export const getCartURL = `${BASE_URL}/cart/cart-items`;

export const addToWishlistURL = `${BASE_URL}/wishlist/add`;  
export const removeFromWishlistURL = `${BASE_URL}/wishlist/remove`;  
export const getWishlistURL = `${BASE_URL}/wishlist/`;

export const createNotificationURL = `${BASE_URL}/notifications/notifications`;
export const getUserNotificationsURL = `${BASE_URL}/notifications/user`;
export const markNotificationAsReadURL = `${BASE_URL}/notifications/read`;
export const getGeneralNotificationsURL = `${BASE_URL}/notifications/gnotif`;

export const createInventoryURL = `${BASE_URL}/inventory/inventory`;
export const getAllInventoriesURL = `${BASE_URL}/inventory/get-inventory`;
export const updateInventoryURL = `${BASE_URL}/inventory/up-inventory`;
export const deleteInventoryURL = `${BASE_URL}/inventory/del-inventory`;
 