import React from 'react';
import { Link } from 'react-router-dom';
 
const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
 
const Settings = () => {
    return (
        <div className="container mx-auto px-8 py-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
                {/* ABOUT Section */}
                <div className="space-y-6">
                    <p className="text-2xl font-bold text-red-900 underline">ABOUT</p>
                    <div className="flex flex-col space-y-3">
                        <Link to="/aboutus" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">About Us</Link>
                        <Link to="/" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Home</Link>
                        <Link to="/offlinestore" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Offline Store</Link>
                    </div>
                </div>
 
                {/* HELP Section */}
                <div className="space-y-6">
                    <p className="text-2xl font-bold text-red-900 underline">HELP</p>
                    <div className="flex flex-col space-y-3">
                    <Link to="/contactus" onClick={scrollToTop} title="Contact details" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Contact Us</Link>
                    <p className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">0129-4067217/+91 9717298198</p>
                    </div>
                </div>
 
                {/* COMPANY POLICY Section */}
                <div className="space-y-6">
                    <p className="text-2xl font-bold text-red-900 underline">COMPANY POLICY</p>
                    <div className="flex flex-col space-y-3">
                         <Link to="/shipping" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Shipping</Link>
                        <Link to="/cancellation" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Cancellation & Return</Link>
                        <Link to="/terms" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Terms of Use</Link>
                        <Link to="/privacypolicy" onClick={scrollToTop} className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Privacy Policy</Link>
                    </div>
                </div>
 
                {/* SOCIAL Section */}
                <div className="space-y-6">
                    <p className="text-2xl font-bold text-red-900 underline">SOCIAL</p>
                    <div className="flex flex-col space-y-3">
                         <Link to="https://www.instagram.com/digi_uncle_creations/" rel="noopener noreferrer" target="_blank" title="Visit our Instagram profile" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Instagram</Link>
                         <Link to="https://www.youtube.com/@DigiUncleCreation" rel="noopener noreferrer" target="_blank" title="Visit our YouTube channel" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">YouTube</Link>
                         <Link to="https://www.google.com/maps/place/DigiUncle+Creations/@28.3959162,77.2667709,17z/data=!4m18!1m9!3m8!1s0x390cdf2ccc7db611:0x313089fc3e02db55!2sDigiUncle+Creations!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9!3m7!1s0x390cdf2ccc7db611:0x313089fc3e02db55!8m2!3d28.3959162!4d77.2667709!9m1!1b1!16s%2Fg%2F11wx9rvqf9?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" rel="noopener noreferrer" target="_blank" title="Check out our reviews" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Google Reviews</Link>
                         <Link to="https://g.page/r/CVXbAj78iTAxECA/review" rel="noopener noreferrer" target="_blank" title="Leave us a review" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">Leave A Review</Link>
                    </div>
                </div>
 
                {/* ADDRESS Section */}
                <div className="space-y-6">
                    <p className="text-2xl font-bold text-red-900 underline">ADDRESS</p>
                    <div className="flex flex-col space-y-3">
                        <Link to="https://www.google.com/maps/place/DigiUncle+Creations/data=!4m2!3m1!1s0x0:0x313089fc3e02db55?sa=X&ved=1t:2428&ictx=111" className="text-lg text-gray-700 font-semibold hover:text-blue-600 transition-colors">
                            Digiuncle Creations, Achievers Center Point, Level-1, Sector 49, Kalindi Hill, Faridabad, Haryana 121001
                        </Link>
                    </div>
                </div>
            </div>
 
            <hr className="my-8 border-gray-500" />
            <div className="flex flex-col items-center justify-between sm:flex-row mt-10 mb-8">
            <Link className="text-2xl font-bold text-gray-800 font-semibold transition-colors duration-300 hover:text-gray-700 dark:text-white dark:hover:text-gray-300">
                        <span className='text-[#41d8f8]'>Digi</span><span className='text-[#f24572]'>Uncle</span>
                    </Link>
                    <p className="mt-4 text-sm text-gray-800 font-semibold sm:mt-0">© Copyright 2023-{new Date().getFullYear()}. All Rights Reserved.</p>
                </div>
        </div>
    );
};
 
export default Settings;
 
 