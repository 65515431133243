import React from 'react';
 
const ShippingAndDelivery = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      padding: '20px',
      marginBottom:'59px'
    }}>
      <div style={{
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '40px',
        maxWidth: '800px',
        width: '100%'
      }}>
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '30px',
            color: '#333',
            fontSize: '48px',
            fontWeight: 'bold'
          }}
        >
          Shipping and Delivery
        </h1>
 
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#777' }}>
          <strong>Last updated on Oct 23, 2024</strong>
        </p>
 
        <section style={{ marginTop: '30px', color: '#555' }}>
          <p>
            For <strong>International buyers</strong>, orders are shipped and delivered through registered international courier
            companies and/or International Speed Post only. For <strong>domestic buyers</strong>, orders are shipped through
            registered domestic courier companies and/or Speed Post only.
          </p>
 
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '15px' }}>
            <li>
              Orders are shipped within <strong>8-14 days</strong> or as per the delivery date agreed at the time of order confirmation.
              Deliveries are subject to the courier company/post office norms.
            </li>
 
            <li>
              <strong>ESPS SOLUTIONS PRIVATE LIMITED</strong> is not liable for any delays caused by the courier company or postal authorities.
              We guarantee to hand over the consignment to the courier company or postal authorities within 8-14 days from the
              date of order and payment or as per the delivery date agreed during order confirmation.
            </li>
 
            <li>
              All orders will be delivered to the <strong>address provided by the buyer</strong> during the order process.
            </li>
 
            <li>
              Delivery of services will be confirmed through the email address provided during registration.
            </li>
 
            <li>
              For any issues related to our services, please contact our helpdesk:
              <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
              <li>📞 <strong>Phone:</strong> 01294067217</li>
                <li>📞 <strong>Mobile:</strong> 9717298198</li>
                <li>📧 <strong>Email:</strong> admin@spsolutions.org.nz</li>
                <li>📧 <strong>Email:</strong> yogenderpsingh007@gmail.com</li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};
 
export default ShippingAndDelivery;