import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from '../context';
import axios from 'axios';
import { paymentURL, verify_paymentURL, invoiceURL, createAddressURL, codURL } from "../api/api";
import toast from 'react-hot-toast';
import { Country, State, City } from "country-state-city";
 
const Checkout = () => {
  const { Cart, clearCart } = useCartContext();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [appliedPromo, setAppliedPromo] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [showSelectAddressModal, setShowSelectAddressModal] = useState(false);

  const pf = useMemo(() => JSON.parse(localStorage.getItem('userData')) || {}, []);
  const ad =  useMemo(() => JSON.parse(localStorage.getItem('address')) || {}, []);

  const [newAddress, setNewAddress] = useState({
    firstName: pf.firstName || '',
    lastName: pf.lastName || '',
    mobile: pf.mobile || '',
    email: pf.email || '',
    user_id: pf.user_id || '',
    addressLine1: ad.addressLine1 || '',
    addressLine2: ad.addressLine2 || '',
    Landmark: ad.Landmark || '',
    city: ad.city || '',
    state: ad.state || '',
    pincode: ad.pincode || '',
    country: ad.country || '',
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  
  const [loading, setLoading] = useState(false);

  // Fetch Countries
useEffect(() => {
  const fetchCountries = () => {
    const countriesData = Country.getAllCountries(); // Fetch all countries
    setCountries(countriesData || []);
  };
  fetchCountries();
}, []);

// Fetch States when selectedCountry changes
useEffect(() => {
  if (selectedCountry) {
    const fetchStates = () => {
      const statesData = State.getStatesOfCountry(selectedCountry);
      setStates(statesData || []);
    };
    fetchStates();
  } else {
    setStates([]);
  }
}, [selectedCountry]);

// Fetch Cities when selectedState changes
useEffect(() => {
  if (selectedState) {
    const fetchCities = () => {
      const citiesData = City.getCitiesOfState(selectedCountry, selectedState);
      setCities(citiesData || []);
    };
    fetchCities();
  } else {
    setCities([]);
  }
}, [selectedState, selectedCountry]);


  const ef =  useMemo(() => JSON.parse(localStorage.getItem('selectedAddress')) || {}, []);

  const [selectedAddress, setSelectedAddress] = useState({
    addressLine1: ef.addressLine1 || '',
    addressLine2: ef.addressLine2 || '',
    Landmark: ef.Landmark || '',
    city: ef.city || '',
    state: ef.state || '',
    pincode: ef.pincode || '',
    country: ef.country || '',
  });

  const userData = useMemo(() => JSON.parse(localStorage.getItem('userData')) || {}, []);

  const scrollToTop = () => window.scrollTo(0, 0);
  const navigateToProductDetails = (skuId) => {
    navigate(`/product/${skuId}`);
    scrollToTop();
};
 
  const toggleSelectAddressModal = () => {
    setShowSelectAddressModal(!showSelectAddressModal);
  }
  const toggleAddAddressModal = () => {
    setShowAddAddressModal(!showAddAddressModal);
  };
 
  useEffect(() => {
    const promoDetails = JSON.parse(localStorage.getItem("appliedPromo")) || null;
    if (promoDetails) {
      setAppliedPromo(promoDetails);
    }
  
    const storedAddresses = JSON.parse(localStorage.getItem("address")) || [];
    setAddresses(storedAddresses);
  }, []); // This runs only once on mount, no dependency on Cart or userData
  
  // Memoize subtotal calculation to avoid re-calculation on every render
  const calculatedSubtotal = useMemo(() => 
    Cart.reduce((total, item) => total + item.selling_price * item.productcount, 0), 
    [Cart] // Only re-run when Cart changes
  );
  
  // Memoize discount calculation to avoid re-calculation on every render
  const calculatedDiscount = useMemo(() => {
    const promoDetails = JSON.parse(localStorage.getItem("appliedPromo"));
    if (promoDetails) {
      return (promoDetails.discountPercentage / 100) * calculatedSubtotal;
    }
    return 0;
  }, [Cart, calculatedSubtotal]); // Only re-run when Cart or calculatedSubtotal changes

  // Use a separate effect to update the state values once the calculations are done
useEffect(() => {
  setSubtotal(calculatedSubtotal);
  setDiscountAmount(calculatedDiscount);
}, [calculatedSubtotal, calculatedDiscount]); // This runs only when calculatedSubtotal or calculatedDiscount changes
 
  const totalAmount = subtotal - discountAmount;
 
  const [addresses, setAddresses] = useState([]);
 
  useEffect(() => {
    const storedAddresses =  JSON.parse(localStorage.getItem("address")) || [];
    setAddresses(storedAddresses);
 
    if (storedAddresses.length === 1) {
      setSelectedAddress(storedAddresses[0]);
    }
  }, []);
 
  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    localStorage.setItem('selectedAddress', JSON.stringify(address)); // Save selected address
    console.log('Selected Address:', address); // Debugging line
    setShowSelectAddressModal(false); // Close the modal after selecting address
  };
  
  
  const toggleModal = () => {
    setShowAddAddressModal(!showAddAddressModal);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };
 
  const handleAddAddress = async (e) => {
    e.preventDefault();
 
    if (!newAddress.addressLine1 || !newAddress.city || !newAddress.state || !newAddress.pincode || !newAddress.country) {
      toast.error("Please fill all required fields.");
      return;
    }
 
    try {
      await axios.post(createAddressURL, { ...newAddress }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
 
      const id = Date.now();
      const updatedAddresses = [...addresses, { ...newAddress, id }];
      setSelectedAddress({ ...newAddress, id });
      localStorage.setItem("address", JSON.stringify(updatedAddresses));
      setAddresses(updatedAddresses);
      toggleModal();
      toast.success("Address added successfully!");
    } catch (error) {
      console.error('Error adding address:', error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };
 
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };
 
  const createOrder = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!selectedAddress || !selectedAddress.addressLine1) {
        toast.error("Please select an address to proceed.");
        return;
      }
 
      const invoiceResponse = await axios.post(invoiceURL, {
        amount: totalAmount,
        currency: 'INR',
        description: 'Purchase from DigiUncle Creations',
        customer: {
          name: userData.firstName,
          email: userData.email,
          contact: userData.mobile,
        },
        notes: {
          address: selectedAddress.addressLine1,
        },
      });
 
      const { id: invoice_id } = invoiceResponse.data;
 
      const response = await axios.post(paymentURL, {
        products: Cart,
        totalAmount,
        appliedPromo,
        shipping_address: selectedAddress,
        invoice_id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      return response.data;
    } catch (error) {
      console.error('Order creation failed:', error);
      alert('Order creation failed! Please try again.');
      return null;
    }
  };
 
  const handleRazorpayPayment = async () => {
    const isLoaded = await loadRazorpayScript();
    if (!isLoaded) {
      alert("Failed to load Razorpay SDK.");
      return;
    }
 
    const { firstName, email, mobile, lastName, user_id } = userData;
    const orderData = await createOrder();
    if (!orderData || !orderData.orderId || !orderData.amount) {
      console.error("Invalid order data:", orderData);
      alert("Order creation failed.");
      return;
    }
 
    const { orderId, amount, currency, key_id } = orderData;
    let verificationResponse;
 
    const options = {
      key: key_id,
      amount,
      currency,
      name: 'DigiUncle Creations',
      description: "Thank you for your purchase!",
      order_id: orderId,
      handler: async (response) => {
        try {
          verificationResponse = await axios.post(verify_paymentURL, {
            orderId,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            invoice_id: response.razorpay_invoice_id,
            orderDetails: {
              products: Cart.map((product) => ({
                SKU_id: product.SKU_id,
                name: product.name,
                price: product.selling_price,
                quantity: product.productcount,
              })),
              amount: totalAmount,
              appliedPromo,
              shipping_address: selectedAddress,
              user_id: userData.user_id,
            },
            customer_details: {
              user_id: user_id,
              firstName: firstName,
              lastName: lastName,
              email: email,
              mobile: mobile,
            }
          });
 
          alert("Payment successful!");
        } catch (error) {
          console.error("Payment verification failed:", error);
          alert("Payment verification failed, but your order has been placed.");
        } finally {
          
          clearCart();

          const { orders } = verificationResponse?.data || {};
          if (orders) {
            localStorage.setItem("orders", JSON.stringify(orders));
            navigate("/orders");
          } else {
            console.error("Orders not found in response:", verificationResponse?.data);
            alert("Error: Unable to fetch updated orders, but your order has been saved.");
            navigate("/orders");
          }
        }
      },
      prefill: {
        name: firstName,
        email: email,
        contact: mobile,
      },
      notes: {
        address: "Customer's address details here",
      },
      theme: {
        color: "#F37254",
      },
    };
 
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleContinue = async (event) => {
    event.preventDefault();

    setLoading(true);
    setTimeout(() => {
     
      setLoading(false);
    }, 3000);

    if (!paymentMethod) {
        alert('Please select a payment method.');
        return;
    }

    if (!selectedAddress || (!selectedAddress.addressLine1 && !newAddress.addressLine1 && !newAddress.city &&
      !newAddress.state && !newAddress.pincode) ) {
      alert('Please select or add an address.');
      return;
    }

    if (paymentMethod === 'Pay Online') {
        handleRazorpayPayment(); // Razorpay flow
    } else {
        try {
            // Reuse logic from createOrder to prepare orderData
            const orderData = {
                product_details: Cart.map((item) => ({
                    SKU_id: item.SKU_id,
                    name: item.name,
                    price: item.selling_price,
                    quantity: item.productcount,
                })),
                promo_code: appliedPromo || null, // Optional promo code
                amount: totalAmount, // Total cart amount
                shipping_address: selectedAddress, // Shipping address from user selection
                customer_details: {
                    user_id: userData.user_id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    mobile: userData.mobile || null,
                },
            };

            // Send COD order request to backend
            const response = await axios.post(codURL, orderData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (response.status === 201) {
              const { orders } = response?.data || {}; // Use 'response' instead of 'verificationResponse'
              if (orders) {
                  localStorage.setItem("orders", JSON.stringify(orders));
              } else {
                  console.error("Orders not found in response:", response?.data);
                  alert("Error: Unable to fetch updated orders, but your order has been saved.");
              }
              alert('Order placed successfully with Cash on Delivery!');
              clearCart();
              localStorage.setItem('Cart', JSON.stringify([])); // Clear cart
              navigate('/orders'); // Redirect to Orders page
          } else {
              throw new Error('Failed to place order.');
          }
          
        } catch (error) {
            console.error('Error placing COD order:', error);
            alert('Something went wrong. Please try again later.');
        }
    }
};
 
  return (
    <>
      <div className="text-center my-5">
        <h1 className="text-3xl font-bold text-black">ORDER DETAILS</h1>
      </div>
 
      <div className="p-5 md:px-10 lg:px-20 flex flex-col lg:flex-row gap-6">
        {/* Cart Items Section */}
        <div className="w-full lg:w-1/2 border rounded-lg">
         <h2 className="flex justify-between bg-gray-200 font-medium text-2xl px-4 py-2">
            IN YOUR CART ({Cart.length})
         <button onClick={() => navigate("/cart")}
            className="bg-blue-500 text-white text-sm px-5 py-2 rounded-md hover:bg-blue-600 transition duration-300"
           >
            Edit Cart
         </button>
         </h2>
 
          <h3 className="font-medium text-xl px-4 py-2 border-b">ARRIVES</h3>
         
          {Cart.map((item) => (
            <div className="flex gap-4 border-b p-4 cursor-pointer" key={item._id} onClick={(e) => {navigateToProductDetails(item.SKU_id); scrollToTop();}}>
              <div className="w-1/2 h-45 md:w-1/3">
                <img src={item.image[0]} className="w-full h-full object-cover" alt={item.name} />
              </div>
              <div className="flex-1">
                <p className="font-medium">{item.name}</p>
                <p>Style</p>
                <p>Qty: {item.productcount}</p>
                <p>Price: ₹{item.selling_price} (per piece)</p>
              </div>
            </div>
          ))}
  </div>
 
 {/* Select Address Modal */}
{showSelectAddressModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg max-w-md w-full">
      <h3 className="text-xl font-medium mb-4">Select an Address</h3>
      <ul className="mb-4">
      {addresses.length > 0 ? (
                    addresses.map((address) => (
                      <div
                        key={address.id}
                        className={`p-3 border rounded-lg cursor-pointer transition duration-300 ${selectedAddress.id === address.id ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-300'}`}
                        onClick={() => handleAddressSelect(address)}
                      >
                        <p className="text-sm text-gray-700">
                        {`${address.addressLine1 ? `${address.addressLine1}, ` : ""} 
  ${address.addressLine2 ? `${address.addressLine2}, ` : ""} 
  ${address.Landmark ? `${address.Landmark}, ` : ""} 
  ${address.city ? `${address.city}, ` : ""} 
  ${address.state ? `${address.state} - ` : ""} 
   ${address.country ? `${address.country}, ` : ""} 
  ${address.pincode ? address.pincode : ""}`}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-red-500">No addresses available. Please add an address.</p>
                  )}
      </ul>
      {/* Add Address Button */}
      <div className="flex mt-4 ml-10 text-center gap-5">
        <button
          className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-600 transition duration-300"
          onClick={toggleAddAddressModal} // Trigger the Add Address Modal
        >
          Add New Address
        </button>
     
      <button
        className="bg-gray-500 text-white py-2 px-10 rounded-lg hover:bg-gray-600 transition duration-300"
        onClick={toggleSelectAddressModal} // Close modal
      >
        Close
      </button></div>
    </div>
  </div>
)}
 
{/* Add Address Modal */}
{showAddAddressModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-4 rounded-lg w-full max-w-xs sm:max-w-sm md:max-w-md h-4/5 overflow-y-auto">
      <h3 className="text-lg font-medium mb-4 text-center">Add New Address</h3>
      <form onSubmit={handleAddAddress}>
        {/* Address Line 1 */}
        <div className="mb-3">
          <label className="block text-sm font-medium">
            Address Line 1 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="addressLine1"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={newAddress.addressLine1}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Address Line 2 (Optional) */}
        <div className="mb-3">
          <label className="block text-sm font-medium">Address Line 2 (Optional)</label>
          <input
            type="text"
            name="addressLine2"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={newAddress.addressLine2}
            onChange={handleInputChange}
          />
        </div>

        {/* Landmark (Optional) */}
        <div className="mb-3">
          <label className="block text-sm font-medium">Landmark (Optional)</label>
          <input
            type="text"
            name="Landmark"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={newAddress.Landmark}
            onChange={handleInputChange}
          />
        </div>

        {/* Country Dropdown */}
        <div className="mb-3">
          <label className="block text-sm font-medium">
            Country <span className="text-red-500">*</span>
          </label>
          <select
            name="country"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={selectedCountry || ""}
            onChange={(e) => {
              const countryIsoCode = e.target.value;
              setSelectedCountry(countryIsoCode);
              setSelectedState(null);
              setSelectedCity(null);
              setCities([]);
              setStates([]);
              const country = countries.find((c) => c.isoCode === countryIsoCode);
              handleInputChange({ target: { name: "country", value: country?.name || "" } });
            }}
            required
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {/* State Dropdown */}
        <div className="mb-3">
          <label className="block text-sm font-medium">
            State <span className="text-red-500">*</span>
          </label>
          <select
            name="state"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={selectedState || ""}
            onChange={(e) => {
              const stateIsoCode = e.target.value;
              setSelectedState(stateIsoCode);
              setSelectedCity(null);
              const state = states.find((s) => s.isoCode === stateIsoCode);
              handleInputChange({ target: { name: "state", value: state?.name || "" } });
            }}
            disabled={!selectedCountry}
            required
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
        </div>

        {/* City Dropdown */}
        <div className="mb-3">
          <label className="block text-sm font-medium">
            City <span className="text-red-500">*</span>
          </label>
          <select
            name="city"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={selectedCity || ""}
            onChange={(e) => {
              const cityName = e.target.value;
              setSelectedCity(cityName);
              handleInputChange({ target: { name: "city", value: cityName } });
            }}
            disabled={!selectedState}
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        {/* Pincode */}
        <div className="mb-3">
          <label className="block text-sm font-medium">
            Pincode <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="pincode"
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            value={newAddress.pincode}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-between gap-2">
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded-lg text-sm w-1/2"
            type="button"
            onClick={toggleAddAddressModal}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-lg text-sm w-1/2"
            type="submit"
          >
            Save Address
          </button>
        </div>
      </form>
    </div>
  </div>
)}

    {/* Summary Section */}
    <div className="w-full lg:w-1/4 border rounded-lg">
          <h2 className="bg-gray-200 font-medium text-2xl px-4 py-2">SUMMARY</h2>
          <div className="px-4 py-3">
            <div className="flex justify-between py-2">
              <span>SUBTOTAL</span> <span>₹{subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between py-2">
              <span>SHIPPING & HANDLING</span> <span className="text-green-500">Free</span>
            </div>
            {appliedPromo && discountAmount > 0 && (
             <div className="flex flex-col py-2">
             <span>Promo Code: {appliedPromo.code}</span>
             <span className="text-green-500">{appliedPromo.discountPercentage}% OFF</span>
             <span className="text-green-500">- ₹{discountAmount.toFixed(2)}</span>
           </div>
            )}
            <div className="flex justify-between text-xl font-semibold py-2">
              <span>TOTAL</span> <span>₹{totalAmount.toFixed(2)}</span>
            </div>
          </div>
        </div>
 
    <div className="w-full lg:w-1/2 border rounded-lg p-5 h-fit ">
          <strong className="block text-center text-red-500 text-lg mb-3">
            {`${newAddress.firstName} ${newAddress.lastName}`}
          </strong>
          <hr className="my-3" />
          <div className="text-center">
            <h3 className="flex justify-center gap-2">
              <b>Phone-No.:</b> <span>{newAddress.mobile}</span>
            </h3>
            <h4 className="text-lg font-semibold border-t mt-5 text-center">Deliver To Address</h4>
            {selectedAddress && selectedAddress.addressLine1 ? (
                newAddress && newAddress.addressLine1 ? (
                  <p className="text-center mt-2">
  {`${newAddress.addressLine1 ? newAddress.addressLine1 : ""} 
     ${newAddress.addressLine2 ? newAddress.addressLine2 : ""} 
     ${newAddress.Landmark ? newAddress.Landmark : ""} 
     ${newAddress.city ? newAddress.city : ""} 
     ${newAddress.state ? newAddress.state : ""} 
     ${newAddress.pincode ? newAddress.pincode : ""}
    ${newAddress.country ? newAddress.country : ""}` }
</p>
) : (
<p className="text-center mt-2">
  {`${selectedAddress.addressLine1 ? selectedAddress.addressLine1 : ""} 
     ${selectedAddress.addressLine2 ? selectedAddress.addressLine2 : ""} 
     ${selectedAddress.Landmark ? selectedAddress.Landmark : ""} 
     ${selectedAddress.city ? selectedAddress.city : ""} 
     ${selectedAddress.state ? selectedAddress.state : ""} 
     ${selectedAddress.pincode ? selectedAddress.pincode : ""}
     ${selectedAddress.country ? selectedAddress.country : ""} `}
</p>
                  )
                ) : (
            <p className="text-center text-sm text-red-500">No address selected. Please select an address.</p>
                )}
 
          </div>
 
          {/* Address Selection Button */}
          <div className="mt-5 text-center">
            <button
              onClick={toggleSelectAddressModal} // Trigger modal opening
              className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Select Address
            </button>
          </div>
 
          {/* Payment Method */}
          <div className="mt-5">
            <h2 className="font-medium">
              Payment Method <span className="text-red-500">*</span>
            </h2>
            {['Pay Online', 'Cash On Delivery'].map((method) => (
              <div key={method} className="mt-2">
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value={method}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />{' '}
                  {method}
                </label>
              </div>
            ))}
          </div>
 
          {/* Continue to Billing Button */}
          <div className="flex justify-between items-center mt-6 px-5 py-3">
            <p className="font-bold">
              Required <span className="text-red-500">*</span>
            </p>
            <button
              onClick={handleContinue}
              className="bg-orange-500 text-white text-lg px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300"
              disabled={loading}
            >
             {loading ? 'Loading...' : 'Place Order'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Checkout;
 