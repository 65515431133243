import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBox, FaShippingFast, FaTruck, FaCheckCircle } from 'react-icons/fa';
import { createReviewURL, updateOrderStatusURL, getOrdersURL  } from "../api/api";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
 
const Orders = () => {
  const [orderList, setOrderList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [activeOrderId, setActiveOrderId] = useState(null);
  const [userName, setUserName] = useState('');
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
  const [selectedReturnReason, setSelectedReturnReason] = useState('');
  const [selectedExchangeReason, setSelectedExchangeReason] = useState('');
  const [returnDescription, setReturnDescription] = useState('');
  const [exchangeDescription, setExchangeDescription] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchOrders = async () => {
      let orders = [];
      try {
        // Get user token from local storage
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("No auth token found.");
          throw new Error("No authorization token found.");
        }
  
        // Fetch orders from the backend
        const response = await axios.get(getOrdersURL, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.status === 200) {
          orders = response.data;
          // Update localStorage with fetched orders
          localStorage.setItem("orders", JSON.stringify(orders));
          console.log("Orders fetched and stored in localStorage.");
        } else {
          console.warn("Failed to fetch orders:", response.data.message);
          throw new Error(response.data.message || "Failed to fetch orders.");
        }
      } catch (error) {
        console.error("Error fetching orders:", error.message);
  
        // Fallback to orders in localStorage
        orders = JSON.parse(localStorage.getItem("orders")) || [];
        console.log("Using orders from localStorage as fallback.");
      }
  
      // Update state with orders (either fetched or fallback)
      setOrderList(orders);
    };
  
    const loadInitialData = () => {
      // Retrieve user data from localStorage
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      const fullName = `${userData.firstName || ""} ${userData.lastName || ""}`.trim();
      setUserName(fullName);
  
      // Retrieve products from localStorage
      const storedProducts = JSON.parse(localStorage.getItem("products")) || [];
      setProductData(storedProducts);
    };
  
    // Load initial data from localStorage
    loadInitialData();
  
    // Fetch orders and update localStorage + state
    fetchOrders();
  }, []);
  

  const openReturnModal = () => {
    setIsReturnModalOpen(true);
  };
 
  const closeReturnModal = () => {
    setIsReturnModalOpen(false);
    setSelectedReturnReason('');
    setReturnDescription('');
  };
 
  const openExchangeModal = () => {
    setIsExchangeModalOpen(true);
  };
 
  const closeExchangeModal = () => {
    setIsExchangeModalOpen(false);
    setSelectedExchangeReason('');
    setExchangeDescription('');
  };
 
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, '0');    
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
 
  const findProductDetails = (skuId) => {
    return productData.find(product => product.SKU_id === skuId);
  };
 
  const scrollToTop = () => window.scrollTo(0, 0);
 
  const navigateToProductDetails = (skuId) => {
    const product = findProductDetails(skuId);
    if (product) {
      navigate(`/product/${product.SKU_id}`);
      scrollToTop();
    } else {
      console.error("Product not found for SKU:", skuId);
    }
  };
  const statusFlow = [
    { status: 'Order Placed', icon: <FaBox className='text-lg'/> },
    { status: 'Shipping', icon: <FaShippingFast className='text-lg'/> },
    { status: 'Out For Delivery', icon: <FaTruck className='text-lg' /> },
    { status: 'Delivered', icon: <FaCheckCircle className='text-lg' /> }
  ];
  const renderStatusFlow = (currentStatus, products, orderId) => {
    const isVerificationStatus =
      currentStatus === 'Unverified Payment' ||
      currentStatus === 'Payment Successful - Verification Pending';
 
    const filteredStatusFlow = statusFlow.filter(
      (status) => status.status === currentStatus || currentStatus !== 'Delivered'
    );
 
    return (
      <>
      {isReturnModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Return Reason</h2>
            <div className="mb-4">
              <select
                value={selectedReturnReason}
                onChange={(e) => setSelectedReturnReason(e.target.value)}
                className="w-full p-2 border rounded-md text-gray-700"
              >
                <option value="">Select a reason</option>
                <option value="Defective">Defective Product</option>
                <option value="Wrong Item">Wrong Item Received</option>
                <option value="Better Price">Found a Better Price</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-4">
              <textarea
                className="w-full p-2 border rounded-md text-gray-700"
                rows="4"
                placeholder="Describe the reason for return (optional)"
                value={returnDescription}
                onChange={(e) => setReturnDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={() => returnOrder(orderId)}
                className="bg-blue-500 text-white px-6 py-2 rounded-full"
              >
                Submit Return Request
              </button>
              <button
                onClick={closeReturnModal}
                className="text-gray-600 font-medium"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
     
      {isExchangeModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Exchange Reason</h2>
            <div className="mb-4">
              <select
                value={selectedExchangeReason}
                onChange={(e) => setSelectedExchangeReason(e.target.value)}
                className="w-full p-2 border rounded-md text-gray-700"
              >
                <option value="">Select a reason</option>
                <option value="Defective">Defective Product</option>
                <option value="Wrong Size">Wrong Size</option>
                <option value="Better Option Available">Better Option Available</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-4">
              <textarea
                className="w-full p-2 border rounded-md text-gray-700"
                rows="4"
                placeholder="Describe the reason for exchange (optional)"
                value={exchangeDescription}
                onChange={(e) => setExchangeDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={() => exchangeOrder(orderId)}
                className="bg-yellow-500 text-white px-6 py-2 rounded-full"
              >
                Submit Exchange Request
              </button>
              <button
                onClick={closeExchangeModal}
                className="text-gray-600 font-medium"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mt-4">
        {currentStatus === 'Cancelled' ? (
          <div className="flex items-center">
            <div className="px-4 py-2 rounded-full font-semibold text-sm bg-red-500 text-white">
              Cancelled
            </div>
          </div>
        ) : isVerificationStatus ? (
          <div className="flex items-center">
            <div className="px-4 py-2 rounded-full font-semibold text-sm bg-yellow-500 text-white">
              {currentStatus}
            </div>
          </div>
        ) : (
          filteredStatusFlow.map((status, index) => (
            <div key={index} className="flex items-center">
              <div
                className={`px-4 py-2 rounded-full font-semibold text-sm flex items-center space-x-2 ${currentStatus === status.status
                    ? 'bg-green-500 text-white'
                    : index < statusFlow.indexOf(status)
                      ? 'bg-green-200 text-gray-800'
                      : 'bg-gray-200 text-gray-700'}`}
              >
                {status.icon}
                <span>{status.status}</span>
              </div>
              {index < filteredStatusFlow.length - 1 && (
                <span className="mx-1 text-gray-400 hidden sm:inline">→</span>
              )}
            </div>
          ))
        )}
 
        {(currentStatus === 'Delivered' || currentStatus === 'Cancelled') && (
          <button
            onClick={() => openReviewModal(products)}
            className="ml-3 px-4 sm:px-8 py-2 bg-blue-500 text-white rounded-full text-sm"
          >
            Write a Review
          </button>
        )}
 
        {currentStatus === 'Delivered' && (
          <>
            <button
               onClick={openReturnModal}
              className="ml-3 px-4 sm:px-8 py-2 bg-orange-700 text-white rounded-full text-sm"
            >
              Return
            </button>
            <button
             onClick={openExchangeModal}
              className="ml-3 px-4 sm:px-8 py-2 bg-yellow-500 text-white rounded-full text-sm"
            >
              Exchange
            </button>
          </>
        )}
 
        {currentStatus === 'Order Placed' && (
          <button
            onClick={() => cancelOrder(orderId)}
            className="ml-3 px-4 sm:px-8 py-2 bg-red-500 text-white rounded-full text-sm"
          >
            Cancel Order
          </button>
        )}
      </div>
      </>
    );
  };
 
  const openReviewModal = (products) => {
    setSelectedOrderProducts(products);
    setSelectedProduct(products[0]?.SKU_id || null);
    setIsReviewOpen(true);
  };
 
  const closeReviewModal = () => {
    setIsReviewOpen(false);
    setReviewText('');
    setRating(0);
    setSelectedProduct(null);
  };
 
  const handleReviewSubmit = async () => {
    if (!selectedProduct || !reviewText.trim() || rating < 1 || rating > 5) {
      alert('Please fill out all fields and ensure the rating is between 1 and 5.');
      return;
    }
 
    try {
      const token = localStorage.getItem('token');
      const reviewData = {
        user_name: userName,
        user_rating: rating,
        user_review: reviewText,
        SKU_Id: selectedProduct,
      };
 
      const response = await axios.post(createReviewURL, reviewData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (response.status === 201) {
        alert('Review submitted successfully');
        const existingReviews = JSON.parse(localStorage.getItem("reviews")) || [];
        const updatedReviews = [...existingReviews, response.data.data];
        localStorage.setItem("reviews", JSON.stringify(updatedReviews));
        closeReviewModal();
      } else {
        console.error("Unexpected response:", response);
        alert("Failed to submit review");
      }
    } catch (error) {
      console.error("Review submission error:", error);
      alert("Error submitting review. Please try again.");
    }
  };
 
  const cancelOrder = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
     
      const response = await axios.put(
        updateOrderStatusURL,
        {
          order_id: orderId,
          Status_details: { status: 'Cancelled' }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
 
      // Log the response to check what is being returned
      console.log('Response:', response);
 
      if (response.status === 200) {
        alert(response.data.message);
        const updatedOrders = orderList.map(order =>
          order.order_id === orderId ? { ...order, Status_details: { status: 'Cancelled' } } : order
        );
        setOrderList(updatedOrders);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));
      } else {
        alert(response.data.message || 'Failed to cancel order');
      }
    } catch (error) {
      console.error("Order cancellation error:", error);
      const errorMessage = error.response?.data?.message || "Error cancelling order. Please try again.";
      alert(errorMessage);
    }
  };
 
  const returnOrder = async (orderId) => {
    if (!selectedReturnReason) {
      alert('Please select a reason for return.');
      return;
    }
 
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Authorization token is missing.');
        return;
      }
 
      const returnData = {
        order_id: orderId,
        Status_details: {
          status: 'Return Requested',  
          reason: "Return-" + selectedReturnReason ,
          description: returnDescription || null,  
        },
      };
     console.log(returnData);
      const response = await axios.put(updateOrderStatusURL, returnData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (response.status === 200) {
       
        closeReturnModal();
        // Update the order list in the frontend
        const updatedOrders = orderList.map(order =>
          order.order_id === orderId ? { ...order, Status_details: { ...order.Status_details, status: 'Return Requested', reason: selectedReturnReason, description: returnDescription || null } } : order
        );
        alert(response.data.message);
 
        setOrderList(updatedOrders);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));
      } else {
        alert('Failed to request return');
      }
      console.log("status", response.data.message);
    } catch (error) {
      alert('Error requesting return. Please try again.');
      console.error('Error:', error);
    }
  };
 
  const exchangeOrder = async (orderId) => {
    if (!selectedExchangeReason) {
      alert('Please select a reason for exchange.');
      return;
    }
 
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Authorization token is missing.');
        return;
      }
 
      const exchangeData = {
        order_id: orderId,
        Status_details: {
          status: 'Exchange Requested',  // Set status to 'Exchange Requested'
          reason: "Exchange-" + selectedExchangeReason,  // Set the reason for exchange
          description: exchangeDescription || null,  // Set the description (can be null if empty)
        },
      };
   
      const response = await axios.put(updateOrderStatusURL, exchangeData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (response.status === 200) {
        alert(response.data.message);
        closeExchangeModal();
        // Update the order list in the frontend
        const updatedOrders = orderList.map(order =>
          order.order_id === orderId ? { ...order, Status_details: { ...order.Status_details, status: 'Exchange Requested', reason: selectedExchangeReason, description: exchangeDescription || null } } : order
        );
        setOrderList(updatedOrders);
        localStorage.setItem('orders', JSON.stringify(updatedOrders));
      } else {
        alert('Failed to request exchange');
      }
    } catch (error) {
      alert('Error requesting exchange. Please try again.');
      console.error('Error:', error);
    }
  };  
 
  const toggleOrderSummary = (orderId) => {
    setActiveOrderId(activeOrderId === orderId ? null : orderId);
  };
 
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-4 sm:px-0">
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6 sm:mb-10">Your Orders</h1>
 
      <div className="w-full max-w-2xl sm:max-w-4xl px-3 sm:px-5">
        {orderList.length > 0 ? (
          orderList.map((order, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden mb-8">
              <div className="px-4 sm:px-6 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h3 className="font-semibold text-lg sm:text-xl text-gray-800 mb-4">Order Details:</h3>
                  <div
                    onClick={() => toggleOrderSummary(order.order_id)}
                    className="cursor-pointer text-blue-500 text-sm sm:text-base flex items-center"
                  >
                    {activeOrderId === order.order_id ? (
                      <FaChevronUp className="mr-2" />
                    ) : (
                      <FaChevronDown className="mr-2" />
                    )}
                    <span>{activeOrderId === order.order_id ? 'Hide Order Summary' : 'Show Order Summary'}</span>
                  </div>
                </div>
                <div className={`grid gap-4 ${order.product_details.length === 1 ? 'grid-cols-1 text-center' : 'md:grid-cols-2'}`}>
                  {order.product_details.map((product, idx) => {
                    const productInfo = findProductDetails(product.SKU_id);
                    return (
                      <div
                        key={idx}
                        className="flex items-center bg-gray-50 rounded-lg shadow-sm p-4 cursor-pointer"
                        onClick={() => navigateToProductDetails(product.SKU_id)}
                      >
                        <img src={productInfo?.image[0]} alt={productInfo?.name} className="rounded-md mb-2 w-16 h-16 sm:w-20 sm:h-20 mr-4 object-contain" />
                        <div>
                          <h4 className="font-semibold text-base sm:text-lg text-gray-800">{productInfo?.name || 'Product Name'}</h4>
                          <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
                          <p className="text-sm text-gray-500">Price: ₹{product.price}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
   
              {activeOrderId === order.order_id && (
                <div className="px-4 sm:px-6 py-4 mt-2 border-gray-200">
                  <p className="text-gray-700 mb-2">
                    Order ID: <span className="font-semibold text-blue-600">{order.order_id}</span>
                  </p>
                  <p className="text-gray-500 text-sm mb-2">
                    Date Placed: <span className="font-medium">{formatDate(order.createdAt)}</span>
                  </p>
                  <p className="text-gray-700 mb-4">
                    Total Amount: <span className="font-bold text-green-600">₹{order.amount}</span>
                  </p>
                  <p className="text-gray-600 text-sm mb-4 pl-4 border-l-4 border-blue-500">
                    Payment Status: {order.payment.status}, Method: {order.payment.method}
                  </p>
                  <p className="font-semibold text-gray-700 mb-2">Shipping Address:</p>
                  <p className="text-gray-600 text-sm mb-4 pl-4 border-l-4 border-blue-500">
                    {order.shipping_address
                      ? `${order.shipping_address.addressLine1}, ${order.shipping_address.addressLine2}, ${order.shipping_address.city}, ${order.shipping_address.state} - ${order.shipping_address.pincode}`
                      : 'Address not available'}
                  </p>
                </div>
              )}
 
              <div className="px-4 sm:px-6 py-4">
                {renderStatusFlow(order.Status_details.status, order.product_details, order.order_id)}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-600 font-medium">
            You have no orders at the moment.
          </div>
        )}
      </div>
 
      {isReviewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Write a Review</h2>
            <div className="mb-4">
              <textarea
                className="w-full p-2 border rounded-md text-gray-700"
                rows="4"
                placeholder="Write your review here..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              ></textarea>
            </div>
            <div className="flex mb-4">
              <label className="mr-4 text-lg mt-1"><b>Rating:</b></label>
              {[1, 2, 3, 4, 5].map((rate) => (
                <span
                key={rate}
                className={`cursor-pointer ${rating >= rate ? 'text-yellow-500' : 'text-gray-400'} text-3xl`}
                onClick={() => setRating(rate)}
              >
                ★
              </span>
             
              ))}
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={handleReviewSubmit}
                className="bg-blue-500 text-white px-6 py-2 rounded-full"
              >
                Submit Review
              </button>
              <button
                onClick={closeReviewModal}
                className="text-gray-600 font-medium"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default Orders;