import { createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { signUpURL, LoginURL, resetPasswordURL, OTPsetURL, verifyOtpURL, googleLoginURL } from "../../api/api";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  // Check for token in localStorage on initial load
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []); // Empty dependency array ensures this only runs once after mount

  const SignUp = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(signUpURL, data);
      const authToken = res.data.token;
      const authMessage = res.data.message;
      const userData = res.data.userData;
      const address = res.data.address;
      const orders = res.data.orders;
      const promoCodes = res.data.promoCodes;
      const generalNotif = res.data.generalNotif;
      const userNotif = res.data.userNotif;
      const wishlist = res.data.wishlist;
     
      if (authToken) {
        setToken(authToken);
        localStorage.setItem('token', authToken);
        localStorage.setItem('message', authMessage);
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('address', JSON.stringify(address));
        localStorage.setItem('orders', JSON.stringify(orders));
        localStorage.setItem('promoCodes', JSON.stringify(promoCodes));
        localStorage.setItem('generalNotif', JSON.stringify(generalNotif));
        localStorage.setItem('userNotif', JSON.stringify(userNotif));
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
      }

      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during sign-up");
    } finally {
      setLoading(false);
    }
  };

  const loginHandler = async (value) => {
    const data = {
        email: value.email,
        password: value.password,
    };

    setLoading(true);

    try {
        const res = await axios.post(LoginURL, data);
        const authToken = res.data.token;
        const authMessage = res.data.message;
        const userData = res.data.userData;
        const address = res.data.address;
        const orders = res.data.orders;
        const promoCodes = res.data.promoCodes;
        const generalNotif = res.data.generalNotif;
        const userNotif = res.data.userNotif;
        const wishlist = res.data.wishlist;

        if (authToken) {
            // Save token and other details to localStorage
            setToken(authToken);
            localStorage.setItem("token", authToken);
            localStorage.setItem("message", authMessage);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("address", JSON.stringify(address));
            localStorage.setItem("orders", JSON.stringify(orders));
            localStorage.setItem("promoCodes", JSON.stringify(promoCodes));
            localStorage.setItem("generalNotif", JSON.stringify(generalNotif));
            localStorage.setItem("userNotif", JSON.stringify(userNotif));
            localStorage.setItem("wishlist", JSON.stringify(wishlist));

            // Display success toast
            //toast.success(authMessage);

            // Return success
            return { success: true, message: authMessage };
        }

        // If no token is received, return failure
        return { success: false, message: "Login failed" };
    } catch (err) {
        console.error("Login error response:", err.response);

        // Display error toast based on the response
        if (!err.response?.data?.errors) {
            toast.error(err.response?.data?.message || "An error occurred.");
        } else {
            if (err.response.data.errors.length > 1) {
                toast.error("Email or Password is required");
            } else {
                toast.error(err.response.data.errors[0].msg);
            }
        }

        // Return failure with the error message
        return { success: false, message: err.response?.data?.message || "Login failed." };
    } finally {
        setLoading(false);
    }
};


  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("message");
        localStorage.removeItem("userData");
        localStorage.removeItem('address');
        localStorage.removeItem('orders');
        localStorage.removeItem('promoCodes');
        localStorage.removeItem('selectedAddress');
        localStorage.removeItem('appliedPromo');
        localStorage.removeItem('Cart');
        localStorage.removeItem('userNotif');
        localStorage.removeItem('wishlist');
        localStorage.removeItem('adminOrders');
        setToken(null);
        window.location.href = '/login'; // Redirect to login page after logout
      }
    });
  };
  
  const onGoogleSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;

    try {
        const response = await axios.post(googleLoginURL, { token: credential }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const {
            token: authToken,
            message: authMessage,
            userData,
            address,
            orders,
            promoCodes,
            generalNotif,
            userNotif,
            wishlist,
        } = response.data;

        if (authToken) {
            // Save token and other user data to state and localStorage
            setToken(authToken);
            localStorage.setItem('token', authToken);
            localStorage.setItem('message', authMessage);
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem('address', JSON.stringify(address));
            localStorage.setItem('orders', JSON.stringify(orders));
            localStorage.setItem('promoCodes', JSON.stringify(promoCodes));
            localStorage.setItem('generalNotif', JSON.stringify(generalNotif));
            localStorage.setItem('userNotif', JSON.stringify(userNotif));
            localStorage.setItem('wishlist', JSON.stringify(wishlist));

            // Return success response for the calling component
            return { success: true, message: authMessage };
        }

        // If token is not returned, send a failure response
        return { success: false, message: "Google login failed" };
    } catch (error) {
        console.error('Google login failed:', error);

        // Return failure response for the calling component
        return {
            success: false,
            message: error.response?.data?.message || "Google login failed due to a server error.",
        };
    }
};



  // OTP handling for both email and mobile
  const OTPset = async ({ email, mobile }) => {
    console.log(email,mobile);
    try {
      // Ensure that either email or mobile is provided, but not both
      if (!email && !mobile) {
        throw new Error('Email or mobile must be provided');
      }
  
      const data = {};
      if (email) data.email = email;
      if (mobile) data.mobile = mobile;
  console.log(data);
      const response = await axios.patch(OTPsetURL, data);
      
      if (response.status === 200) {
        return response.data; // Assuming the backend returns a message like 'OTP sent'
      } else {
        throw new Error('Failed to send OTP');
      }
    } catch (error) {
      console.error("Error in sending OTP:", error.response ? error.response.data : error.message);
      throw error; // Let the caller handle the error as needed
    }
  };
  

  const resetPassword = async ({ email, mobile, newPassword }) => {
    try {
      const data = { password: newPassword };
  
      // Add either email or mobile to the request body
      if (email) {
        data.email = email;
      } else if (mobile) {
        data.mobile = mobile;
      }
  
      console.log('Request Data:', data);  // Log the request data
  
      const response = await axios.post(resetPasswordURL, data);
      
      // If the response is successful, return the data
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Failed to reset password");
        console.error('Error Response:', response);  // Log the error response
        return null;
      }
    } catch (error) {
      console.error('Error in resetting password:', error);  // Log the error
      toast.error("An error occurred while resetting password");
      
      // Capture the full error details (response, message, etc.)
      if (error.response) {
        console.error('Error Response:', error.response.data);  // Log server response data
      } else if (error.request) {
        console.error('Error Request:', error.request);  // Log the request that was made
      } else {
        console.error('Error Message:', error.message);  // Log the error message
      }
      return null;
    }
  };
  
  // OTP verification for email or mobile with a purpose
  const verifyOtp = async ({ otp, email, mobile, purpose }) => {
    try {
      console.log(otp, email, mobile, purpose);
      // Ensure that either email or mobile is provided for OTP verification
      const data = { otp, purpose };
      
      // Add either email or mobile to the request body
      if (email) {
        data.email = email;
      } else if (mobile) {
        data.mobile = mobile;
      }
  
      if (!data.email && !data.mobile) {
        throw new Error('Email or mobile must be provided for OTP verification');
      } 
      const response = await axios.post(verifyOtpURL, data);
  
      if (response.status === 200) {
        return response.data; // Return the success data from backend
      } else {
        throw new Error('Failed to verify OTP');
      }
    } catch (error) {
      console.error("Error in verifying OTP:", error.response ? error.response.data : error.message);
      toast.error(error.response ? error.response.data.message : 'Incorrect OTP. Please try again.');
      return null; // Return null to indicate failure
    }
  };
  
  
  return (
    <AuthContext.Provider
      value={{
        loginHandler,
        token,
        loading,
        logoutHandler,
        onGoogleSuccess,
        SignUp,
        resetPassword,
        verifyOtp,
        OTPset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
