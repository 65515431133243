import React, { useState } from 'react';
import axios from 'axios';
import { FaRegUser, FaRegEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { userUpdateURL } from '../api/api';
import { useAuthContext } from '../context';

const Profile = () => {
  const navigate = useNavigate();
  const { OTPset } = useAuthContext();

  const pf = JSON.parse(localStorage.getItem('userData')) || {};

  const [data, setData] = useState({
    firstName: pf.firstName || '',
    lastName: pf.lastName || '',
    email: pf.email || '',
    mobile: pf.mobile || '',
  });
  const [editMode, setEditMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        userUpdateURL,
        { ...data, id: pf.user_id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      alert(response.data.message);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  const handleOTP = async () => {
    console.log("handleOTP called");
    try {
      const email = pf.email;
      const result = await OTPset({ email });
      console.log(result);
      if (result) {
        toast.success("OTP sent to email");
        console.log("Navigating to /verify-otp...");
        navigate('/verify-otp', { state: { purpose: 'passwordChange' } });
      }
    } catch (error) {
      console.error("Error in handleOTP:", error);
      toast.error("An error occurred while sending OTP");
    }
  };

  const handleEmail = async () => {
    const email = pf.email || '';
    if (!email) {
      toast.error("Email is required to proceed.");
      return;
    }
    if (!pf.isEmailVerified) {
      try {
        const result = await OTPset({ email });
        if (result) {
          toast.success("OTP sent to email");
          navigate('/verify-otp', { state: { email, purpose: 'emailVerification', fromProfile: true } });
          return;
        }
      } catch (error) {
        toast.error("Failed to send OTP");
        return;
      }
    } else {
      navigate("/profile");
      toast.success("Email Verification Successful");
    }
  };

  return (
    <div className="h-fit flex items-center justify-center py-6 px-4 sm:px-8 mb-12 md:mb-0">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg w-full max-w-4xl ">
        <div className="flex flex-col lg:flex-row">
          {/* Profile Picture and Status */}
          <div className="w-full lg:w-1/3 flex flex-col items-center p-4 border-b lg:border-b-0 lg:border-r lg:border-r-2">
            <div className="profile-section mb-4">
              {pf.picture ? (
                <img
                  src={pf.picture}
                  alt={`${pf.firstName} ${pf.lastName}`}
                  className="profile-picture w-20 h-20 rounded-full"
                />
              ) : (
                <FaRegUser className="text-4xl text-gray-600" />
              )}
            </div>
            <h1 className="text-lg font-semibold">{data.firstName} {data.lastName}</h1>
            <span className="bg-green-500 text-white text-sm px-3 py-1 rounded mt-1">Active</span>
            {pf.isEmailVerified && pf.password && (
              <button
                onClick={handleOTP}
                className="bg-yellow-600 text-white text-sm px-4 py-2 mt-2 rounded-md hover:bg-yellow-700 transition duration-300"
              >
                Reset Password
              </button>
            )}
            {!pf.isEmailVerified && (
              <div className="flex flex-wrap justify-center gap-2 mt-2">
                <button
                  onClick={handleEmail}
                  className="bg-blue-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Verify Email
                </button>
                <button
                  onClick={handleOTP}
                  className="bg-yellow-600 text-white text-sm px-4 py-2 rounded-md hover:bg-yellow-700 transition duration-300"
                >
                  Reset Password
                </button>
              </div>
            )}
          </div>

          {/* Profile Details */}
          <div className="w-full lg:w-2/3 p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-red-600">Your Profile</h2>
              <button
                className="flex items-center text-blue-500 gap-2"
                onClick={() => setEditMode(!editMode)}
              >
                <FaRegEdit size={20} />
                <span>Edit/Update</span>
              </button>
            </div>

            {editMode ? (
              <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block font-semibold">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Contact No.</label>
                  <input
                    type="text"
                    name="mobile"
                    value={data.mobile}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div className="col-span-1 sm:col-span-2 text-right">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="font-bold text-lg">First Name:</label>
                  <p className="text-gray-700 text-sm">{data.firstName}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Last Name:</label>
                  <p className="text-gray-700 text-sm">{data.lastName}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Email:</label>
                  <p className="text-gray-700 text-sm">{data.email}</p>
                </div>
                <div>
                  <label className="font-bold text-lg">Contact No.:</label>
                  <p className="text-gray-700 text-sm">
                    {data.mobile ? data.mobile : "Update your profile to add a contact number!"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
