import React, { useState, useEffect } from 'react';
import { useWishlistContext } from '../../context';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars';
import toast from 'react-hot-toast';
 
const ProductCard = ({ data = [] }) => {
    const { Wishlist, addToWishlist, removeFromWishlist } = useWishlistContext();
    const scrollToTop = () => window.scrollTo(0, 0);

    const isInWishlist = (productId) => {
        return Wishlist.some((wishlistItem) => wishlistItem.SKU_id === productId);
    };

    const handleWishlistToggle = (item) => {
        const token = localStorage.getItem("token");

        if (!token) {
            toast.error("Please log in to add items to your wishlist.");
            return;
        }

        if (isInWishlist(item.SKU_id)) {
            removeFromWishlist(item.SKU_id);
        } else {
            addToWishlist(item);
        }
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 px-4 py-8">
            {data.map((item) => {
                const { _id, name, image, currency = '₹', MRP_price, selling_price, rating = 0, SKU_id } = item;
                const imageUrl = Array.isArray(image) ? image[0] : image;

                return (
                    <div
                        key={_id}
                        className="border p-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 flex flex-col justify-between w-half"
                    >
                        {/* Image Section */}
                        <div className="relative w-full h-48 mb-4">
                            <Link to={`/product/${SKU_id}`} onClick={() => scrollToTop()}>
                                <img
                                    src={imageUrl}
                                    alt={name}
                                    className="w-full h-full object-contain rounded-md transition-all duration-300 transform hover:scale-105"
                                />
                            </Link>
                            <button
                                className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
                                onClick={() => handleWishlistToggle(item)}
                            >
                                <FaHeart
                                    className={`text-gray-500 ${isInWishlist(SKU_id) ? 'text-red-500' : ''}`}
                                />
                            </button>
                        </div>

                        {/* Product Details */}
                        <div className="text-center p-1" style={{ backgroundColor: '#f8e9ca', borderRadius: '0 0 10px 10px', color: 'black' }}>
                            <Link to={`/product/${SKU_id}`} onClick={() => scrollToTop()}>
                                <h3 className="sm:text-base font-semibold text-black truncate">{name}</h3>

                                <div className="flex justify-center items-center space-x-2">
                                    <span className="text-sm sm:text-base font-semibold text-gray-700">
                                        {currency}{selling_price}
                                    </span>
                                    {MRP_price && (
                                        <span className="text-xs sm:text-sm line-through text-red-500">
                                            {currency}{MRP_price}
                                        </span>
                                    )}
                                     <span className="ml-2 text-green-600 text-sm">
                                  {((item.MRP_price - item.selling_price) / item.MRP_price * 100).toFixed(0)}% Off
                                  </span>
                                </div>

                                <div className="flex items-center justify-center">
                                    <ReactStars
                                        count={5}
                                        value={rating}
                                        size={20}
                                        edit={false}
                                        activeColor="#ffd700"
                                    />
                                    <span className="ml-2 text-sm text-gray-600">({rating})</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductCard;