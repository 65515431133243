import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { imageURL, updateProductURL } from "../../api/api";
 
const EditProductForm = ({ token, onSuccess, initialSKUId }) => {
    const [editData, setEditData] = useState({
        SKU_id: "",
    });
    const [editFields, setEditFields] = useState([{ heading: "", value: "" }]);
    const selectedFilesRef = useRef([]);
 
    // Set SKU ID when the component renders
    useEffect(() => {
        if (initialSKUId) {
            setEditData({ ...editData, SKU_id: initialSKUId });
        }
    }, [initialSKUId]);
 
    const handleEditChange = (index, e) => {
        const { name, value } = e.target;
        const updatedFields = [...editFields];
        updatedFields[index][name] = value;
        setEditFields(updatedFields);
    };
    const [loading, setLoading] = useState(false); // Initialize loading as false
 
    const handleFileUpdate = (e) => {
        const files = Array.from(e.target.files);
        selectedFilesRef.current = [...selectedFilesRef.current, ...files];
    };
 
    const handleAddField = () => {
        setEditFields([...editFields, { heading: "", value: "" }]);
    };
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            const { SKU_id } = editData;
 
            if (!SKU_id) {
                alert("Please provide a SKU ID");
                setLoading(false); // Stop loading if validation fails
                return;
            }
 
            const filterFields = editFields.reduce((obj, field) => {
                if (field.heading === "Links" && field.value) {
                    obj[field.heading] = field.value.split(",").map(link => link.trim());
                } else if (field.value) {
                    obj[field.heading] = field.value;
                }
                return obj;
            }, {});
 
            if (Object.keys(filterFields).length === 0 && selectedFilesRef.current.length === 0) {
                alert("Please fill in the required fields or upload images.");
                setLoading(false); // Stop loading if nothing to update
                return;
            }
 
            let fieldUpdateMessage = "";
            let imageUpdateMessage = "";
 
            if (Object.keys(filterFields).length > 0) {
                const response = await axios.put(updateProductURL, { SKU_id, ...filterFields }, {
                    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                });
                fieldUpdateMessage = response.data.message || "Product fields updated successfully.";
            }
 
            if (selectedFilesRef.current.length > 0) {
                const formData = new FormData();
                selectedFilesRef.current.forEach(file => formData.append("files", file));
                formData.append("SKU_id", SKU_id);
 
                const response = await axios.post(imageURL, formData, {
                    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
                });
                imageUpdateMessage = response.data.message || "Images uploaded successfully.";
            }
 
            const successMessage = [fieldUpdateMessage, imageUpdateMessage].filter(Boolean).join(" ");
            if (successMessage) {
                onSuccess(successMessage);
            } else {
                alert("No updates were made.");
            }
 
            resetFormFields();
        } catch (error) {
            console.error("Error updating product:", error);
            alert(`An error occurred: ${error.message}`);
        } finally {
            setLoading(false); // Stop loading after the process completes
        }
    };
 
    const resetFormFields = () => {
        setEditData({ SKU_id: "" });
        setEditFields([{ heading: "", value: "" }]);
        selectedFilesRef.current = [];
    };
 
    return (
        <div>
            <form onSubmit={handleSubmit}>
                {/* SKU ID */}
                <div className="mb-6">
                    <label className="block text-md font-semibold text-gray-700 mb-2" htmlFor="SKU_id">
                        SKU ID
                    </label>
                    <input
                        className="form-control w-full border-2 border-gray-300 py-2 px-4 rounded-md text-sm focus:ring-2 focus:ring-blue-500 outline-none transition duration-200"
                        type="text"
                        name="SKU_id"
                        value={editData.SKU_id}
                        onChange={(e) => setEditData({ ...editData, SKU_id: e.target.value })}
                        placeholder="Enter SKU ID"
                        disabled // Disable the field to prevent changes
                    />
                </div>
 
                {/* Dynamic Fields */}
                {editFields.map((field, index) => (
                    <div key={index} className="mb-6">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1">
                                <label className="block text-md font-semibold text-gray-700 mb-2" htmlFor={`heading-${index}`}>
                                    Field Heading
                                </label>
                                <select
                                    className="form-control w-full border-2 border-gray-300 py-2 px-4 rounded-md text-sm focus:ring-2 focus:ring-blue-500 outline-none transition duration-200"
                                    name="heading"
                                    value={field.heading}
                                    onChange={(e) => handleEditChange(index, e)}
                                >
                                    <option value="" disabled>Select Heading</option>
                                    <option value="name">Product Name</option>
                                    <option value="brand">Brand Name</option>
                                    <option value="IN_stock">Stock</option>
                                    <option value="listing">Listing</option>
                                    <option value="product_color">Product Colour</option>
                                    <option value="category">Category</option>
                                    <option value="material">Material</option>
                                    <option value="weight">Weight</option>
                                    <option value="Height">Height</option>
                                    <option value="Width">Width</option>
                                    <option value="Length">Length</option>
                                    <option value="Depth">Depth</option>
                                    <option value="description">Description</option>
                                    <option value="MRP_price">MRP</option>
                                    <option value="selling_price">Selling Price</option>
                                    <option value="Links">Links</option>
                                    <option value="image">Images</option>
                                </select>
                            </div>
                             {/* Field Value Input */}
                             {field.heading && field.heading !== "image" && field.heading !== "Links" && (
                                <div className="flex-1">
                                    <label className="block text-md font-semibold text-gray-700 mb-2" htmlFor={`value-${index}`}>
                                        Field Value
                                    </label>
                                    <input
                                        className="form-control w-full border-2 border-gray-300 py-2 px-4 rounded-md text-sm focus:ring-2 focus:ring-blue-500 outline-none transition duration-200"
                                        type="text"
                                        name="value"
                                        value={field.value}
                                        onChange={(e) => handleEditChange(index, e)}
                                        placeholder="Enter value"
                                    />
                                </div>
                            )}
                                {/* Links Input */}
                                {field.heading === "Links" && (
                                <div className="flex-1">
                                    <label className="block text-md font-semibold text-gray-700 mb-2" htmlFor={`value-${index}`}>
                                        Enter Links (comma separated)
                                    </label>
                                    <input
                                        className="form-control w-full border-2 border-gray-300 py-2 px-4 rounded-md text-sm focus:ring-2 focus:ring-blue-500 outline-none transition duration-200"
                                        type="text"
                                        name="value"
                                        value={field.value}
                                        onChange={(e) => handleEditChange(index, e)}
                                        placeholder="Enter links separated by commas"
                                    />
                                </div>
                            )}
 
                            {/* File Input */}
                            {field.heading === "image" && (
                                <div className="flex-1">
                                    <label className="block text-md font-semibold text-gray-700 mb-2" htmlFor={`value-${index}`}>
                                        Upload Images
                                    </label>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileUpdate}
                                        className="form-control w-full border-2 border-gray-300 py-2 px-4 rounded-md text-sm focus:ring-2 focus:ring-blue-500 outline-none transition duration-200"
                                    />
                                </div>
                            )}
 
                        </div>
                    </div>
                ))}
                 {/* Add Field Button */}
                 <button
                    type="button"
                    onClick={handleAddField}
                    className="mt-2 bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700 focus:outline-none transition duration-200"
                >
                    Add Field
                </button>
                {/* Submit Button */}
                <button
                    type="submit"
                    className="mt-6 w-full bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 focus:outline-none transition duration-200"
                >
                    {loading?'Loading':'Update product'}
                </button>
            </form>
        </div>
    );
};
 
export default EditProductForm;
 
 