import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios if not already done
import { FaEdit, FaTrash } from 'react-icons/fa';
import EditProductForm from './EditProductForm';
import toast from "react-hot-toast";
import { useNavigate, Link } from 'react-router-dom';
import { getproductURL } from "../../api/api";
 
const ProductManagement = () => {
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem('userData');
  const navigate = useNavigate();
 
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
 
 
  // Fetch products from the API on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(getproductURL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.data && res.data.data) {
          // Ensure data is valid
          setProducts(res.data.data);
          localStorage.setItem('products', JSON.stringify(res.data.data));
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        setError("Error fetching data"); // Set error message
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };
 
    getData();
  }, [getproductURL, token]); // Add dependencies if necessary
 
  // Handle Product Deletion
  const handleDeleteProduct = (productId) => {
    const updatedProducts = products.filter((product) => product.SKU_id !== productId);
    setProducts(updatedProducts);
    localStorage.setItem('products', JSON.stringify(updatedProducts));
    toast.success('Product deleted successfully!');
  };
 
  // Handle Product Editing
  const handleEditProduct = (productId) => {
    setEditProductId(productId);
    setIsModalOpen(true);
  };
 
  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditProductId(null);
  };
 
  const handleSuccess = (message) => {
    toast.success(message);
    handleModalClose();
    // Optionally, refresh the product list from localStorage or an API
  };
 
  let userType = null;
  if (userData) {
    userType = JSON.parse(userData);
  }
 
  if (!userType) {
    navigate('/');
    return null;
  }
 
  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }
 
  if (error) {
    return <div>{error}</div>; // Show error message
  }
 
  if (userType.type === "admin") {
    return (
      <div className="container mx-auto p-4 md:mb-0 mb-20">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Product Listings</h1>
          <Link to='/add-product' state={{ token }}>
            <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 ml-auto">
              Add Product
            </button>
          </Link>
        </div>
        <div className="overflow-x-auto max-h-[400px] border border-gray-300 rounded-lg shadow-md">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-4 py-2">Image</th>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">SKU</th>
              <th className="border border-gray-300 px-4 py-2">Price</th>
              <th className="border border-gray-300 px-4 py-2">Stock</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.SKU_id} className="hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">
                  <img
                    src={product.image[0]}
                    alt={product.name}
                    className="h-16 w-16 object-cover rounded-md"
                  />
                </td>
                <td className="border border-gray-300 px-4 py-2">{product.name}</td>
                <td className="border border-gray-300 px-4 py-2">{product.SKU_id}</td>
                <td className="border border-gray-300 px-4 py-2">₹{product.selling_price}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {product.IN_stock === 'in-stock' ? 'In Stock' : 'Out of Stock'}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded-md mr-2 hover:bg-blue-600"
                    onClick={() => handleEditProduct(product.SKU_id)}
                  >
                    <FaEdit className="inline mr-1" /> Edit
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                    onClick={() => handleDeleteProduct(product.SKU_id)}
                  >
                    <FaTrash className="inline mr-1" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        {/* Edit Product Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 w-3/4 max-w-2xl relative">
              <button
                onClick={handleModalClose}
                className="absolute top-4 right-4 text-gray-500 hover:text-black"
              >
                &times;
              </button>
              <EditProductForm
                token={token}
                onSuccess={handleSuccess}
                initialSKUId={editProductId}
              />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    toast.error("Unauthorized access");
    navigate('/');
    return null;
  }
};
 
export default ProductManagement;
 
 