import { Cart, CheckOut, Orders, VerifyOtp, AdminPanel, Inventory } from "../pages";
import { AddProductForm, ProductManagement } from "../components"
import Profile from "../pages/Profile";

export const privateRoutes = [
    {
        path : "/Cart",
        element : <Cart />
    },
    {
        path : "/profile",
        element : <Profile />
    },
    {
        path : "/checkout",
        element : <CheckOut />
    },
    {
        path : "/orders",
        element : <Orders />
    },
    {
        path : '/verify-otp',
        element : <VerifyOtp/>,
    },
    {
        path : '/admin-panel',
        element : <AdminPanel/>,
    },
    {
        path : '/product-management',
        element : <ProductManagement/>,
    },
    {
        path : '/add-product',
        element : <AddProductForm/>,
    },
    {
        path : '/product-inventory',
        element : <Inventory/>,
    },
    
]