import React from 'react';
 
const CancellationAndRefund = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      padding: '20px',
      marginBottom:'59px'
    }}>
      <div style={{
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '40px',
        maxWidth: '800px',
        width: '100%'
      }}>
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '30px',
            color: '#333',
            fontSize: '48px',
            fontWeight: 'bold'
          }}
        >
          Cancellation and Refund
        </h1>
 
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#777' }}>
          <strong>Last updated on Oct 23, 2024</strong>
        </p>
 
        <section style={{ marginTop: '30px', color: '#555' }}>
          <p>
            <strong>ESPS SOLUTIONS PRIVATE LIMITED</strong> believes in helping its customers as much as possible
            and follows a liberal cancellation policy. The terms are outlined below:
          </p>
 
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '15px' }}>
            <li>
              Cancellations will be considered only if the request is made within <strong>7 days</strong> of placing the order.
              However, cancellation requests may not be accepted if the order has already been communicated to vendors/merchants
              and shipping has been initiated.
            </li>
 
            <li>
              <strong>Perishable items</strong> such as flowers and eatables are not eligible for cancellation.
              However, a refund or replacement can be arranged if the customer proves that the product’s quality was unsatisfactory.
            </li>
 
            <li>
              If you receive a <strong>damaged or defective item</strong>, report it to our Customer Service team within
              <strong> 7 days</strong> of receiving the product. Your request will be processed after the merchant has
              verified the issue.
            </li>
 
            <li>
              If the product does not match the description on our website or your expectations, notify our Customer Service
              within <strong>7 days</strong> of receiving it. After reviewing your complaint, our team will take appropriate action.
            </li>
 
            <li>
              For products with a <strong>manufacturer’s warranty</strong>, please contact the manufacturer directly for support.
            </li>
 
            <li>
              Approved refunds will be processed by <strong>ESPS SOLUTIONS PRIVATE LIMITED</strong> within
              <strong> 6-8 days</strong> to the original payment method.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};
 
export default CancellationAndRefund;